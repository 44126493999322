export const environment = {
  production: true,

  // URL of Production API
  apiUrl: 'https://lifegate-broker-app.us.aldryn.io/',
  emailUrl: 'https://lifegate-broker-app.us.aldryn.io/sendmail',
  liveUrl: 'https://maklermandat.ch/',

  brokerMail: 'e-mandate@life-gate.ch',
};
